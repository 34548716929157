import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import theme from '../helpers/theme'
import Layout from '../components/layout'
// import LinkOut from '../components/link-out'
// import Markdown from '../components/markdown'
import RichText from '../components/richtext'
import IMG from '../components/image'
import SEO from '../components/seo'

const FeaturePage = (props) => {
	
	const { data, pageContext } = props
  const settings = data.settings.edges[0].node

  const {
    title,
    metaDescription,
    coverImage,
    navImage,
    shareImage,
    articleLong,
    category
  } = data.contentfulFeature;

  return (
    <Layout image={navImage || settings.navImageFeatures} theme={theme.split} size="small" settings={props.data.settings.edges[0].node} location={props.location} splashSeen={true}>
      <SEO title={title} description={metaDescription} shareImage={shareImage} />

      <section className="article-header">
        <hgroup className="masthead span-8 guttersx2 mbx2">
          <h2 className="adjust mbx2">{category ? category.name : 'Feature'}</h2>
          <h1 className="adjust">
            {title}
          </h1>
        </hgroup>
        <div className="span-8 rauto">
          <div className="event-poster padx2">
            <picture>
              <IMG {...coverImage} />
            </picture>
          </div>
        </div>
      </section>

      <section className="inner guttersx2">
        <article>
          <RichText json={articleLong.json} locale={pageContext.locale} />
        </article>
      </section>

    </Layout>
  )
}

FeaturePage.propTypes = {
  data: PropTypes.object.isRequired
}

export default FeaturePage

export const pageQuery = graphql`
  query featureQuery($slug: String!, $locale: String!) {
    settings: allContentfulSettings(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          ...Settings
        }
      }
    }
    contentfulFeature(slug: {eq: $slug}, node_locale: {eq: $locale}) {
      title
      slug
      metaDescription
      coverImage {
        fluid(maxWidth: 600, quality: 90) {
          srcSet,
          sizes,
          src,
          aspectRatio
        }
      }
      shareImage: coverImage {
        fluid(maxWidth: 600, quality: 70) {
          src
        }
      }
      articleLong {
        json
      }
      category {
        name
      }
    }
  }
`
